import { Link } from 'react-router-dom'
import { type FC } from 'react'
import { PaymentStatus, MTNtracking, type FridayGivingDTO, type FridayGivingRun } from '@shamaazi/mytennights'
import { type PartialRecord } from '@lib/services'
import { useAuth } from '@lib/hooks'
import { ScheduleTestIds } from '@lib/testing'
import { getDateFromTimestamp, getFridayGivingAmount } from '~/service'

interface statusColor {
  bg: string
  text: string
}

const paymentStatusColors: PartialRecord<string, statusColor> = {
  [PaymentStatus.Skipped]: { bg: 'bg-mtn-blue-200', text: 'text-mtn-blue-200' },
  [PaymentStatus.Cancelled]: { bg: 'bg-mtn-gray', text: 'text-mtn-red' },
  [PaymentStatus.Failed]: { bg: 'bg-mtn-red', text: 'text-mtn-red' },
  [PaymentStatus.Succeeded]: { bg: 'bg-mtn-blue', text: 'text-mtn-blue' }
}

export const getPaymentStatusColor = (status: string): { bg: string, text: string } => {
  return paymentStatusColors[status] ?? { bg: 'bg-mtn-gray', text: 'text-mtn-gray' }
}

interface FridayBreakdownProps {
  fGScheduleID: string
  schedule: FridayGivingDTO
  payment: FridayGivingRun
}

export const FridayBreakdown: FC<FridayBreakdownProps> = ({ fGScheduleID, payment }) => {
  const { user } = useAuth()

  const color = getPaymentStatusColor(payment.payment_status)

  return <div
        data-test-id={`${ScheduleTestIds.night_}${payment.friday_giving_schedule_id}`}
        key={payment.friday_giving_schedule_id}
        className={`${payment.payment_status === PaymentStatus.Skipped ? 'opacity-80' : ''} h-60 font-medium bg-mtn-gray-300 rounded flex flex-col items-center justify-center text-center`}>
        <span className={`${color.text} text-xs `}>{getDateFromTimestamp(payment.payment_date)/* .toLocaleString({ weekday: 'short', month: 'long', day: 'numeric' }) */}</span>
        <span className={`${color.text} text-mtn-blue-800 my-4 text-xl `}>{payment.payment_status !== PaymentStatus.Skipped ? getFridayGivingAmount(payment)
          : 'Unavailable'}</span>
        <span className={`${color.text} text-sm my-3`}>Payment {payment.payment_status}</span>

        {payment.payment_status !== PaymentStatus.Skipped && <Link to={`/account/fridayGiving/${fGScheduleID}/run/${payment.payment_date}`} className="mt-2 text-xs underline"
            onClick={() => MTNtracking.viewScheduleDetails(user?.email)}>View Details</Link>}
    </div>
}
