import { type ButtonLocation } from '~/pages'
import { type FridayGivingDTO, type FridayGivingRun, MTNtracking, ScrollHash, scrollToHash } from '@shamaazi/mytennights'
import { DateTime } from 'luxon'
import { GetCurrencyByString, displayCurrency } from '@lib/services'

const onDonateNowClick = (location: ButtonLocation, email: string): void => {
  MTNtracking.goToSplitForm(email, location)
  scrollToHash(ScrollHash.length)
}

const getTimeFromTimestamp = (timestamp: string): string => {
  return DateTime.fromISO(timestamp).toLocaleString(DateTime.TIME_24_SIMPLE)
}

const getDateFromTimestamp = (timestamp: string): string => {
  return DateTime.fromISO(timestamp).toLocaleString(DateTime.DATE_MED)
}

export const getFridayGivingAmount = (fridayGiving: FridayGivingDTO | FridayGivingRun): string => {
  const amount = fridayGiving.pay_fees ? (fridayGiving.amount + fridayGiving.fees + fridayGiving.tip_amount + fridayGiving.tip_fees) : (fridayGiving.amount + fridayGiving.tip_amount)
  return displayCurrency({ amount, currency: GetCurrencyByString(fridayGiving.currency) })
}

const MultiCharityTitle = 'WHERE MOST NEEDED'

export { onDonateNowClick, MultiCharityTitle, getTimeFromTimestamp, getDateFromTimestamp }
